import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function Footer() {
  return (
    <footer class="footer  w-[100%] font-open   pb-3 px-10 bg-blue-300 ">
      <div class=" list-none flex flex-col md:flex-row justify-between ">
        <div class="f_right w-full md:w-1/2 m-4">
          <h4 class=" text-[15px] font-bold pb-3">CONTACT US</h4>
          <div class="f_right_contact-1 ">
            <li>
              <LocalPhoneIcon
                style={{
                  marginRight: "5px",
                  fontSize: "16px",
                  color: "#1F19A8",
                }}
              />
              <span class="pb-3 text-[14px]">+91 93240 64211</span>
            </li>
          </div>
        </div>
      </div>
    </footer>
  );
}
