import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../index.css";

export default function OpenAcc() {
  const [isfixed, setIsFixed] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = window.scrollY;
      let threshold;

      // Define threshold values based on screen size
      if (window.innerWidth > 768) {
        threshold = 445; // Large screen threshold
      } else {
        threshold = 284; // Small screen threshold
      }

      if (scrollPosition > threshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`pl-[10px] p-[1rem] flex w-[100%] justify-center z-10 ${
        isfixed ? "fixed top-[4.5rem]" : ""
      }`}
    >
      <a href="https://cutt.ly/Cw4iELcw" alt="open-acc-link">
        <div className="open-acc rounded-full bg-black w-[fit-content] justify-center  top-[15rem] items-center flex ">
          <div className="w-[50px] flex animate-pulse hover:animate-nonejustify-center items-center  rounded-full p-[12px]  text-white ">
            <AccountCircleIcon />
          </div>

          <p className="tooltiptext text-container   p-[12px]  text-white rounded-full">
            Open your Trading & demat account now
          </p>
        </div>
      </a>
    </div>
  );
}
