import React from "react";
import OpenAcc from "../../../components/OpenAcc";
import Navbar from "../../../components/Navbar";

export default function Broking() {
  return (
    <>
      <Navbar />
      <OpenAcc />
    </>
  );
}
